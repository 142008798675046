@import "../../../../mixins.scss";

.portfolio_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h5 {
        font-weight: 600;
        font-size: 20px;
        
        
        @include md {
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
        }

        color: #000000;
        display: inline-flex;
        align-items: center;
        gap: 20px;
        text-align: start;
        a{
            font-size: 16px;
            display: block;
            padding: 5px 15px;
            border-radius: 3px;
            background-color: transparent;
            border: 1px solid #2B292C;
            color: #2B292C;
            transition: .2s linear;
            text-align: center;
            text-decoration: none;
            &:hover{
                background-color: #2B292C;
                color: #fff;
            }
        }
    }

    .content {
        width: 100%;
        display: grid;
        grid-gap: 20px;
        grid-template-areas:
            "vertical"
            "long"
            "bottom_first"
            "bottom_last"
            "title_grano"
            "description_grano";

        @include md {
            grid-gap: 45px;
            grid-template-columns: 2.5fr 2.1fr 2.7fr;
            grid-template-areas:
                "vertical long long"
                "vertical bottom_first bottom_last"
                "title_grano description_grano description_grano";
        }

        .vertical,
        .long,
        .bottom_first,
        .bottom_last {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 20px;
            filter: grayscale(15%);
            transition: filter 0.5s linear;

            .titles {
                span {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    padding: 5px;
                    color: #fff;
                    background: #000000;
                    border-radius: 5px;
                    transition: 0.5s;
                }

                &>p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 140%;
                    @include md {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                    }

                    color: #FFFFFF;
                }
            }

            .description {
                transform-origin: 0 0;
                transform: scaleY(0);
                transition: all 0.2s ease-in-out;
                color: #fff;
            }

            &:hover {
                filter: grayscale(95%);

                .titles {
                    span {
                        background: #fff;
                        color: #000000;
                    }
                }

                .description {
                    transform: scaleY(1);

                }
            }
        }

        .vertical {
            grid-area: vertical;
        }

        .long {
            grid-area: long;
        }

        .title_grano {
            grid-area: title_grano;
            margin-top: 55px;

            h6 {
                font-weight: 600;
                font-size: 24px;
                line-height: 140%;
                @include md{
                    font-size: 32px;
                    line-height: 39px;
                }
                color: #000000;
                margin: 0;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #7B7C8A;
            }
        }

        .description_grano {
            grid-area: description_grano;
            @include md{
                margin-top: 55px;
            }
            p {
                margin: 0;
                color: #000000;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                @include md{
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                }
            }

        }
    }
}