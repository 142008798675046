@import "../../../../mixins.scss";

.slider_wrapper {
    width: 100%;

    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        margin: 20px auto;
        @include lg {
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            margin: 45px auto;
        }
        color: #000000;
        text-align: center;
    }
}
@include lg{
    .separator{
        margin-top: 100px;
    }
}