html{
  --colorgray: #2B292C;
  --colorwhite: #fff;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #fff;
    overflow-x: hidden;
  }
}
a, button, input[type="submit"]{
  cursor: pointer;
}
.App{
  height: fit-content;
}