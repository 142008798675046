@import '../../../../mixins.scss';


.slider_wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h5{
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        margin-bottom: 45px;
        @include md {
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
        }
        color: #000000;
        text-align: start;
    }
    .content{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        text-align: center;
        @include lg {
            display: grid;
            grid-auto-rows: 300px;
            grid-template-columns: 45% 45%;
            gap: 50px 0px;
        }
        .content_block{
            border: 1px solid black;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            padding: 45px;
            * {
                margin: 0 20% ;
            }
            @include md{
                gap: 25px;
            }
            @include lg {
                padding: 0;
                text-align: start;
                display: grid;
                grid-gap: 5% 15%;
                grid-template-columns: 1fr 3fr;
                grid-template-rows: 2;
                grid-template-areas: 
                "image title"
                "image description"
                ;
            }
            img{
                grid-area: image;
                max-width: 25%;
                object-fit: contain;
                width: 100%;
                @include lg{
                    width: 100%;
                    height: 100%;
                    max-width: unset;
                }
            }
            span{
                grid-area: title;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                margin: 0;
                height: 100%;
                display: flex;
                align-items: flex-end;
                text-align: center;
                @include lg{
                    text-align: start;
                    margin-right: 20%;
                }
                
            }
            p{
                grid-area: description;
                margin: 0;
                height: 100%;
                align-items: flex-start;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                @include lg{
                    text-align: start;
                    margin-right: 20%;
                }
                a{
                    margin: 0;
                    font: inherit;
                    white-space: nowrap;
                    color: black;
                }
            }
        }
    }
}