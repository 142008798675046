@import "../../../mixins.scss";

.detail_container {
    max-width: 1610px;
    margin: 0 auto;
    padding: 0 16px;
    .catalog_item {
        margin: 45px auto;
        max-width: 374px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include lg{
            max-width: 1147px;

        }
        .image {
            border-radius: 8px;
            background-color: #2B292C;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            width: 100%;
            height: fit-content;
            border: 1px solid #2B292d;
            @include lg{
                width: 33%;
            }
            img{
                width: 100%;
                max-height: 200px;
                object-fit: contain;
                background-color: #fff;
                @include lg{
                    max-height: unset;
                }
            }
        }

        .filters {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 25px;
            width: 100%;
            height: fit-content;
            @include lg{
                width: 60%;
            }
            .description{
                width: 100%;
            }
        }

        .button {
            width: 100%;
            margin-top: 45px;

            button {
                background-color: #2B292C;
                width: 100%;
                height: 100%;
                font-weight: 500;
                font-size: 32px;
                line-height: 39px;
                color: #fff;
                border: none;
                padding: 27px 0;
                transition: 0.2s linear;
                border: 1px solid #2B292C;
                &:hover{
                    color: #2B292C;
                    background-color: #fff;
                    border-radius: 15px;
                }
            }
        }
    }
    .delivery{
        max-width: 1147px;
        margin: 45px auto;
    }
}