@import "../../../mixins.scss";

.container{
    max-width: 1610px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 25px;
    @include lg{
        gap: 45px;
    }
}