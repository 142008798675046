@import "../../../../mixins.scss";

.production_wrapper {
  position: relative;
  overflow: hidden;
  padding: 20px 16px;
  @include md {
    background: rgba(0, 0, 0, 0.7);
    padding: 0;
  }
  .Bcg_Mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    object-position: top center;
    object-fit: cover;
    z-index: -1;

    top: 0;
    left: 0;
    @include md {
      display: none;
    }
  }
  video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    display: none;
    top: -30px;
    @include md {
      display: block;
    }
    source {
      width: 100vw;
      height: 100%;
    }
  }
  .container {
    max-width: 1610px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 15px;
    @include md {
      flex-direction: row;
      margin-top: 60px;
      margin-bottom: 40px;
    }
    @include lg {
      margin-top: 150px;
    }
    justify-content: space-between;
    .text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 25px;
      @include md {
        width: 45%;
      }
      @include lg {
        width: 50%;
      }
      @include xl {
        width: 33%;
        display: block;
        margin: 0;
      }

      h3 {
        color: #ccd6d8;
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        @include md {
          text-align: start;
          font-weight: 600;
          font-size: 36px;
          line-height: 44px;
        }
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #ccd6d8;
        margin: 15px 0;
        text-align: center;
        @include md {
          text-align: start;
          margin-bottom: 100px;
          font-size: 24px;
          line-height: 29px;
        }
      }
      button {
        border: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        border-radius: 5px;
        color: #fff;
        background: #2b292c;
        padding: 10px 15px;
        box-shadow: 4px 4px 22px 4px rgba(255, 255, 255, 0.25);
        transition: 0.2s linear;
        margin: 0 auto;
        @include md {
          border-radius: 15px;
          padding: 17px 25px;
          font-size: 24px;
          line-height: 29px;
          margin: 0;
          &:hover {
            background: #fff;
            color: #2b292c;
          }
        }
      }
    }
    .slider {
      width: 100%;
      @include md {
        width: 45%;
      }
      @include lg {
        width: 50%;
      }
      @include xl {
        width: 30%;
      }
      .slide {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 400px;
        height: 250px;
        background-color: var(--colorgray);
        border: 0;
        object-fit: contain;
        @include md {
          height: 350px;
        }
      }
    }
  }
}
