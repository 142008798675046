@mixin sm {
    @media (min-width: 320px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 760px) {
        @content;
    }
}
@mixin lg {
    @media (min-width: 960px) {
        @content;
    }
}
@mixin xl {
    @media (min-width: 1280px) {
        @content;
    }
}