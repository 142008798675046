@import "../../../mixins.scss";

.PortfolioPage {
    max-width: 1610px;
    margin: 0 auto;
    padding: 0 16px;
    &>a{
        text-decoration: none;
        font-size: 60px;
        color: #000000;
    }

    .portfolio_container {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 40px;
    }
}

.content {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-auto-rows: 150px;
    grid-template-areas:
        "vertical"
        "long"
        "bottom_first"
        "bottom_last";

    @include md {
        grid-gap: 45px;
        grid-template-columns: 2.5fr 2.1fr 2.7fr;
        grid-auto-rows: 378px;
        grid-template-areas:
            "vertical long long"
            "vertical bottom_first bottom_last"
    }
    & > div {
        cursor: pointer;
    }
    .vertical,
    .long,
    .bottom_first,
    .bottom_last {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
        filter: grayscale(15%);
        transition: filter 0.5s linear;

        .titles {
            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                padding: 5px;
                color: #fff;
                background: #000000;
                border-radius: 5px;
                transition: 0.5s;
            }

            &>p {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;

                @include md {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                }

                color: #FFFFFF;
            }
        }

        .description {
            transform-origin: 0 0;
            transform: scaleY(0);
            transition: all 0.2s ease-in-out;
            color: #fff;
        }

        &:hover {
            filter: grayscale(95%);

            .titles {
                span {
                    background: #fff;
                    color: #000000;
                }
            }

            .description {
                transform: scaleY(1);

            }
        }
    }

    .vertical {
        grid-area: vertical;
    }

    .long {
        grid-area: long;
    }

    .title_grano {
        grid-area: title_grano;
        margin-top: 55px;

        h6 {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;

            @include md {
                font-size: 32px;
                line-height: 39px;
            }

            color: #000000;
            margin: 0;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #7B7C8A;
        }
    }

    .description_grano {
        grid-area: description_grano;

        @include md {
            margin-top: 55px;
        }

        p {
            margin: 0;
            color: #000000;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;

            @include md {
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
            }
        }

    }
}
.title{
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
}
.subtitle{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}
.firstType{
    display: grid;
    grid-template-areas: 
    'second second'
    'last last'
    'first first';
    @include md {
    grid-template-areas: 
    'first second second'
    'first last last';
    }
    & > div:first-child {
        grid-area: first;
    }
    & > div{
        grid-area: second;
    }
    & > div:last-child {
        grid-area: last;
    }
}

.secondType {
    display: grid;
    grid-template-areas: 
    'second second'
    'last last';
    grid-template-areas: 
    'first first'
    'second second'
    'last last';
    @include md {
    grid-template-areas: 
    'first second second'
    'first last last';
    }
    & > div:first-child {
        grid-area: first;
    }
    & > div{
        grid-area: second;
    }
    & > div:last-child {
        grid-area: last;
    }
}
.thirdType {
    display: grid;
    grid-template-areas: 
    'second first'
    'last last';
    grid-template-columns: 1fr 1fr;
    & > div:first-child {
        grid-area: first;
    }
    & > div{
        grid-area: second;
    }
    & > div:last-child {
        grid-area: last;
    }
}
.fourthType {
    display: grid;
    grid-template-areas: 
    'first first'
    'second last';
    grid-template-columns: 1fr 1fr;
    & > div:first-child {
        grid-area: first;
    }
    & > div{
        grid-area: second;
    }
    & > div:last-child {
        grid-area: last;
    }
}
.maskModal{
    background-color: var(--colorgray);
    position: fixed;
    z-index: 9999;
    opacity: .5;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}
.close_btn{
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 999999;
    top: 10px;
    right: 10px;
    cursor: pointer;
    @include lg{
        top: 25px;
        right: 25px;
    }
    &::after, &::before{
        content: ' ';
        display: block;
        width: 30px;
        height: 5px;
        background-color: #fff;
        border-radius: 5px;
        transform: rotate(-45deg);
        transform-origin: center center;
        position: absolute;
        top: 0;
        left: 0;
    }
    &::before{
        transform: rotate(45deg);
    }
}
.modal{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50px;
    width: 100vw;
    height: calc(100vh - 50px);

    max-height: 100vh;
    z-index: 99999;
    @include lg{
        width: 100vw;
        height: calc(100vh - 100px);
    }
    .slide{
        @include lg{
            width: 100vw ;
            height: calc(100vh - 100px);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}