@import "../../../../../mixins.scss";

.delivery {
    border: 1px solid black;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    padding: 10px;
    grid-gap: 10px;
    grid-template-areas:
    "p p"
    "btn btn"
    "img img";
    @include lg{
        grid-template-areas:
        "btn img"
        "p img";
        grid-gap: 20px 160px;
        padding: 15px 100px;
    }
    img {
        width: 100%;
        height: 100%;
        max-height: 250px;
        grid-area: img;
        object-fit: contain;
    }

    button {
        grid-area: btn;
        background-color: #2B292C;
        height: 60px;
        border-radius: 30px;
        border: none;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #fff;
        transition: 0.2s ease-in-out;
        margin: 20px;
        @include lg{
            margin-top: 40px;
        }
        &:hover {
            background-color: #fff;
            border: 1px solid #2B292C;
            color: #2B292C;
        }
    }

    p {
        grid-area: p;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        margin: 0;
        color: #000000;
        text-align: center;
        @include lg{
            text-align: start;
        }
    }
}
.delivery_description{
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: 0.2s ease-out;
    height: 0;
    h6{
        font-size: 24px;
        line-height: 140%;

    }
}
.delivery_show{
    transform: scaleY(1);
    height: auto;
}