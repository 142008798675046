@import "../../../../mixins.scss";

.separator {
  margin: 0;
  display: none;

  @include lg {
    display: block;
  }

  @include lg {
    margin-bottom: 45px;
  }
}

.production_wrapper {
  width: 100%;
  margin: 0 auto;
  display: none;

  @include xl {
    display: block;
  }
}

h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
  text-align: center;
  margin: 0;
  margin-bottom: 16px;

  @include lg {
    font-size: 32px;
    line-height: 39px;
  }
}

.content {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 942px;
    bottom: 0px;
    transform: matrix(-7, 2.1, 3.7, 0.5, 0, 0);
    z-index: 999;
    width: 100px;
    height: 100px;
  }
  .text {
    margin: 0;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;

    @include lg {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      display: block;
      max-width: 60%;
      margin: 0 auto;
    }
  }

  .scheme_block {
    margin: 0 auto;
    margin-top: 45px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: fit-content;
    .mask {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: relative;
      & > * {
        position: absolute;
        filter: brightness(70%);
        transition: 0.2s linear;
        z-index: 2;

        &:hover, &.active {
          filter: brightness(100%);
        }
      }

      .Angel {
        left: 557px;
        top: 14px;
        z-index: 2;
      }

      .Fence {
        top: 226px;
        left: 256px;
        z-index: 5;

        &_mask {
          content: "";
          display: block;
          position: absolute;
          top: 230px;
          left: 260px;
          z-index: 99;
          width: 250px;
          height: 54px;
          transform: matrix(-2, 0.5, 0, 0.6, 277, 89);

          &:hover ~ .Fence {
            filter: brightness(100%) !important;
          }
        }
      }

      .Floor {
        top: 289px;
        left: 255px;
        z-index: 4;

        &_mask {
          content: "";
          display: block;
          position: absolute;
          top: 226px;
          left: 259px;
          z-index: 7;
          width: 86px;
          height: 54px;
          transform: matrix(-2, 0.4, 1.1, 0.6, 201, 62);

          &:hover ~ .Floor {
            filter: brightness(100%) !important;
          }
        }
      }

      .Heart {
        top: 98px;
        left: 630px;
        z-index: 3;
      }

      .Marbles {
        top: 213px;
        left: 268px;
        z-index: 6;
      }

      .Plate {
        top: 265px;
        left: 640px;
        z-index: 8;
      }

      .Stand {
        top: 242px;
        left: 568px;
        z-index: 7;
      }

      .Vase {
        top: 235px;
        left: 733px;
        z-index: 9;
      }
    }

    & > img {
      user-select: none;
      pointer-events: none;
      display: none;
      position: relative;
      z-index: -1;
      @include lg {
        display: block;
      }
    }

    .puncts_left {
      position: absolute;
      top: 0;
      left: 0;
      width: fit-content;
    }
  }
}

.puncts {
  &_left {
    position: absolute;
    z-index: 2;
    top: 25%;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    width: 25%;
    & > .p1,
    .p2,
    .p3,
    .p4 {
      cursor: pointer;
      display: block;
      width: max-content;
      width: 200px;
      border: 1px solid #2b292c;
      color: #2b292c;
      font-size: 18px;
      line-height: 140%;
      font-weight: 600;
      padding: 10px 15px;
      transition: 0.2s linear;
      display: flex;
      gap: 15px;
      white-space: nowrap;
      & > span {
        font-size: 16px;
        line-height: 140%;
        font-weight: 300;
        width: 0;
        transform-origin: top left;
        transform: scaleX(0);
        white-space: nowrap;
        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      &:hover,
      &.active {
        width: max-content;
        color: #fff;
        background-color: #2b292c;

        & > span {
          width: unset;
          transform: scaleX(1);
        }
      }
    }
  }

  &_left {
    border-left: 2px solid #2b292c;
    & > div {
      border-left: none;
      text-align: start;
      border-radius: 0px 10px 10px 0px;
    }
  }

  &_right {
    justify-content: flex-end;
    align-items: flex-end;
    left: unset;
    right: 0;
    border-right: 2px solid #2b292c;
    & > div {
      border-right: none;
      flex-direction: row-reverse;
      text-align: end;
      border-radius: 10px 0px 0px 10px;
    }
  }
}
