@import "../../../../../mixins.scss";

.filter {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  height: auto;

  label {
    display: block;
    width: fit-content;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    * {
      margin: 0;
      padding: 0;
      display: block;
      color: #676767;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
    }
  }
  select {
    width: 100%;
    @include lg {
      width: 264px;
      min-width: 110px;
    }
  }
}
