@import "../../mixins.scss";
header {
    position: sticky;
}
.container{
    background-color: var(--colorgray);
    transition: margin 0.2s linear;
    margin-top: -120px;
    padding: 0 15px;
    @include lg{
        margin-top: 0;
    }
    .header_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1610px;
        margin: 0 auto;
        flex-direction: column-reverse;
        padding: 16px;
        @include lg{
            padding: 0;
            flex-direction: row;
        }
        .logo{
            color: var(--colorwhite);
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 100%;
            width: 100%;
            @include lg{
                width: unset;
            }
            & > button{
                background-color: transparent;
                border: 1px solid #ffffff;
                color: #ffffff;
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 5px;
                font-weight: 350;
                font-size: 16px;
                line-height: 140%;
                cursor: pointer;
                span{
                    font-size: 16px;
                    line-height: 100%;
                    font-weight: 500;
                    transition: transform 0.2s linear;
                    position: relative;
                    top: -2px;
                }
                @include lg {
                    display: none;
                }
            }
            a{
                color: inherit;
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 5px;
                user-select: none;
                .logotype{
                    color: inherit;
                    width: 44px;
                    height: 41px;
                    text-align: center;
                    svg{
                        height: 100%;
                    }
                }
                h1{
                    margin: 0;
                    padding: 0;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    text-transform: uppercase;
                }
                h2{
                    margin: 0;
                    padding: 0;
                    font-weight: 350;
                    font-size: 11px;
                    line-height: 13px;
                    text-transform: uppercase;
                }
            }
            
        }
        .text_block{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-wrap: nowrap;
            color: var(--colorwhite);
            gap: 0 55px;
            width: 100%;
            @include lg{
                width: unset;
                flex-direction: row;
                justify-content: flex-end;
            }
            .adress{
                max-width: 50%;
                color: inherit;
                text-decoration: none;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
            a{
                padding: 0 10px;
                color: inherit;
                text-decoration: none;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
            .links{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 5px;
                a{
                    padding: 0;
                }
            }
        }
    }
}
.arrow {
    width: 15px;
}
.openContact{
    margin: 0;
    .openedArrow{
        transform: scaleY(-1);
        top: 4px!important;
    }
}