@import "../../../mixins.scss";

.catalog_container{
    max-width: 1610px;
    margin: 0 auto;
    padding: 0 16px;
    .catalog_nav{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 45px;
        border-radius: 10px;
        overflow: hidden;
        .all{
            width: 100%;
        }
        a{
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #2B292C;
            color: #fff;
            padding: 15px;
            box-sizing: border-box;
            text-align: center;
            border: 1px solid #fff;
            transition: transform, 0.2s linear;
            font-style: normal;
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            user-select: none;
            @include lg{
                width: calc(25%);
                font-size: 32px;
                line-height: 39px;
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .catalog_items{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        @include lg{
            gap: 45px 66px;
        }
        a{
            display: block;
            width: fit-content;
            text-decoration: none;
            width: 100%;
            max-width: calc(50% - 7.5px);
            @include lg{
                max-width: calc(25% - 50px);
                font-size: 32px;
                line-height: 39px;
            }
        }
    }
    .delivery{
        max-width: 1156px;
        margin: 45px auto;
    }
}