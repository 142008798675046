@import "../../../../mixins.scss";

.catalog_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2B292C;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    border-top: 2px solid var(--colorgray);
    box-sizing: border-box;
    transition: all .2s linear;
    &:hover{
        box-shadow: 0px 0px 14px 4px rgba(34, 60, 80, 0.2);
    }
    .image {
        width: 100%;
        @include lg{
            height: 490px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-color: #fff;
        }
    }
    p {
        margin: 10px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 140%;
        font-size: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        @include lg{
            height: 60px;
            font-size: 20px;
            line-height: 24px;
        }
    }
}