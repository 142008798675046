.container{
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: 50px auto;
}
.numbers{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    .four, .zero{
        width: 100px;
        height: 200px;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    .zero{
        height: 170px;
    }
    .four, .zero{
        svg{
            path {
                stroke-width: 15;
                stroke-dasharray: 1588;
                stroke-dashoffset: 1588;
                animation: draw 3s ease-in-out forwards;
             }

        }
    }
    .four path{
        stroke: var(--colorgray);
    }
    .zero path{
        stroke: var(--colorgray);
    }
}
.text{
    text-align: center;
    .title{
        font-size: 40px;
        color: var(--color-text);
    }
    .description{
        font-size: 32px;
        color: var(--color-text);
    }
}
.links{
    display: flex;
    align-items: center;
    justify-content: center;
    a{
        font-size: 16px;
    background-color: var(--colorgray);
        color: var(--colorwhite);
        padding: 15px;
        border-radius: 5px;
        text-transform: uppercase;
        transition: color 0.2s linear;
        text-decoration: none;
        border: 1px solid var(--colorgray);
        transition: .2s linear;
        &:hover{
            background-color: var(--colorwhite);
            color: var(--colorgray);
            text-decoration: underline;
        }
    }
}
@keyframes draw {
    100% {
       stroke-dashoffset: 0;
    }
 }