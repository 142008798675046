.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    top: 0;
    .mask{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(43, 41, 44, 0.5);
        z-index: -1;
    }
    .close{
        cursor: pointer;
        &::before, &::after{
            content: '';
            position: absolute;
            right: 40px;
            top: 40px;
            display: block;
            background-color: #fff;
            border-radius: 5px;
            width: 25px;
            height: 5px;
            transform: rotate(-45deg);
            z-index: 99;
        }
        &::before{
            transform: rotate(45deg);
        }
    }
    .form{
        max-width: 1620px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        h6 {
            padding: 0 75px !important;
        }
    }
}