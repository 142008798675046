@import "../../mixins.scss";

footer {
    background-color: #474745;
    margin-top: 45px;
    padding: 16px;
    box-sizing: border-box;
    @include lg{
        width: 100%;
    }
    .footer_block {
        display: flex;
        max-width: 1610px;
        margin: 0 auto;
        color: #fff;
        justify-content: space-between;
        flex-wrap: wrap;
        @include lg{
            gap: 25px;
        }
        .column {
            width: 50%;
            @include md{
                width: 20%;
            }
            @include xl{
                margin: 50px 0;
                width: unset;
                max-width: 33%;
            }
            .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 140%;
                @include lg{
                    font-size: 32px;
                    line-height: 39px;
                }
            }
            ul{
                list-style-type: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 5px;
                @include lg{
                    gap: 18px;
                }

                li{
                    margin: 0;
                    padding: 0;
                    color: #7B7C8A;
                    a{
                        color: inherit;
                        text-decoration: none;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        transition: color 0.2s ease;
                        @include lg{
                            font-size: 20px;
                            line-height: 24px;

                        }
                        &:hover{
                            color: #fff;
                        }
                    }
                    .adress_tel{
                        font: inherit;
                    }
                }
            }
        }
    }
}