@import "../../../../mixins.scss";

.map_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px 0;

  @include lg {
    flex-direction: row;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include lg {
      width: unset;
    }

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      margin: 0;
      margin-bottom: 25px;

      @include lg {
        margin-bottom: 45px;
        font-size: 32px;
        line-height: 39px;
      }
    }

    p,
    a,
    li {
      margin: 0;
      color: #000;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    ul {
      list-style-type: none;
      margin: 1ch 0;
      padding: 0;

      li {
        margin: 0;

        a {
          text-decoration: none;
        }
      }
    }

    .adresses {
      transform-origin: 0 0;
      transform: scaleY(0);
      transition: 0.2s linear;
      background-color: var(--colorgray);
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px 15px;
      border-radius: 5px;
      position: relative;
      top: 5px;
      height: 0;
      &::before {
        content: "";
        position: absolute;
        border-bottom: 15px solid var(--colorgray);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        height: 5px;
        top: -25px;
        left: 15px;
        display: block;
      }

      &_active {
        transform: scaleY(1);
        height: fit-content;
      }

      * {
        color: #fff;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .map {
    height: 100%;
    width: 100%;
    position: relative;
    @include lg {
      width: 75%;
    }

    & > div:first-child {
      width: 100% !important;
      height: 397px !important;
    }
    .baloon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      padding: 15px 20px;
      background-color: var(--colorgray);
      color: #fff;
      height: 50%;
      box-sizing: border-box;
      animation: baloon 0.3s linear forwards;
      transform-origin: right;
      @include lg {
        max-width: 20%;
        height: 100%;
        top: 0;
        right: 0;
      }
      @keyframes baloon {
        from {
          transform: scaleX(0);
        }
        to {
          transform: scaleX(1);
        }
      }
      * {
        display: block;
        margin: 0;
        padding: 0;
        text-align: end;
        box-sizing: border-box;
      }
      > div {
        margin-bottom: 10px;
        a {
          color: #fff;
          text-decoration: underline;
          display: inline;
        }
      }
      .closebtn {
        padding: 10px;
        position: absolute;
        bottom: 0px;
        left: 0;
        margin: 0;
        width: 100%;
        text-align: center;
        border: 1px solid #fff;
        cursor: pointer;
        transition: 0.2s linear;
        &:hover {
          background-color: #fff;
          color: var(--colorgray);
          border: 1px solid var(--colorgray);
        }
      }
    }
  }
}
.adressbtn {
  border: none;
  padding: 0;
  background-color: unset;
  color: var(--colorgray);
  text-decoration: underline;
  text-align: left;
}
