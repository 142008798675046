@import '../../../../mixins.scss';


.production_wrapper{
    width: 100%;
    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        margin: 20px auto;
        @include lg {
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            margin: 45px auto;
        }
        color: #000000;
        text-align: start;
        text-align: center;
    }
   .content{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    @include lg{
        gap: 45px;
    }
    @include lg{
    }
    & > .content_item{
        height: 85px;
        width: calc(50% - 5px);
        @include md{
            height: 150px;
            width: calc(50% - 22.5px);
        }
        @include lg{
            height: 242px;
            width: calc(25% - 45px);
        }
        a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            width: 100%;
            text-decoration: none;
            background-color: rgba($color: #000000, $alpha: 0.6);
            transition: 0.2s;
            border-radius: 6px;
            overflow: hidden;
            @include lg{
                border-radius: 8px;
            }
            img{
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: -1;
                filter: grayscale(100%);
                object-fit: cover;
                transition: 0.1s;
            }
            p{
                font-size: 18px;
                margin: 10px 0;
                color: #fff;
                @include md {
                    font-size: 24px;
                }
            }
            &:hover{
                background-color: rgba($color: #000000, $alpha: 0.4);
                img{
                    transform: scale(1.1);
                }
            }
        }
    }
   } 
}