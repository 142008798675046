.video_slider {
    .swiper-slide{
        display: flex;
        align-items: center;
        justify-content: center;
        &-active{
            z-index: 999;
            position: relative;
        }
    }
    .swiper-pagination {
        position: static;
        margin-top: 10px;
        .swiper-pagination-bullet {
            background-color: #D9D9D9;
            opacity: 1;
            width: 12px;
            height: 12px;
        }
        .swiper-pagination-bullet-active{
            width: 12px;
            height: 12px;
            background-color: #646464;;
        }
    }
}