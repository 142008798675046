@import "../../mixins.scss";

.form {
  position: relative;
  width: 100%;
  background-color: #2b292c;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 95px;
  padding: 20px 0 25px 0;
  @include lg {
    padding: 40px 0 80px 0;
    border-radius: 15px;
  }

  @include lg {
    border-radius: 45px;
  }

  h6 {
    margin: 0;
    margin-bottom: 45px;
    color: #fff;
    text-align: start;
    letter-spacing: 1.2px;
    margin-bottom: 37px;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    padding: 0 20px;
    text-align: center;

    @include lg {
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: start;
    }
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 90%;
    gap: 20px;
    @include lg {
      gap: 45px;
      flex-direction: row;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      flex-flow: column;
      flex-shrink: 1;
      gap: 20px;
      @include lg {
        gap: 40px;
        height: 404px;
        width: calc(57% - 20px);

        &:last-child {
          width: calc(38% - 20px);
        }
      }
    }

    input,
    select {
      background-color: transparent;
      border-radius: 0;
      border: 1px solid #fff;
      color: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-weight: 300;
      font-size: 16px;
      line-height: 140%;
      padding: 10px 15px;
      @include lg {
        font-weight: 300;
        font-size: 32px;
        line-height: 39px;
        padding: 15px 36px;
      }
      option {
        background-color: #fff;
        color: #2b292c;
        transition: all 0.2s linear;

        &:hover {
          background-color: #2b292c;
          color: #fff;
        }
      }

      &::-ms-expand {
        display: none;
      }
    }

    input[type="submit"] {
      color: #ffffff;
      width: 100%;
      margin: 0 auto;
      transition: 0.2s linear;
      padding: 10px 85px;
      font-weight: 300;
      font-size: 16px;
      line-height: 140%;
      &:hover {
        background-color: #fff;
        color: #2b292c;
      }
      @include lg {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        padding: 22px 85px;
      }
    }

    textarea {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      min-height: 150px;
      resize: none;
      background-color: transparent;
      border-radius: 0;
      border: 1px solid #fff;
      color: #fff;
      font-weight: 300;
      font-size: 16px;
      line-height: 140%;
      padding: 10px;

      @include lg {
        min-height: none;
        height: 100%;
      }

      @include lg {
        padding: 30px;
        font-weight: 300;
        font-size: 32px;
        line-height: 39px;
      }
    }
  }
}
.notvisible {
  visibility: hidden;
}
.thanks {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 25px;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  *{
    padding: 0;
    margin: 0;
  }
}
